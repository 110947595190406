<template>
<form ref="form" name="form_auth" method="post" :action="gatewayURL">
    <input type="hidden" name="site_cd" :value="siteCode" />
    <input type="hidden" name="ordr_idxx" :value="orderID" />
    <input type="hidden" name="req_tx" value="cert" />
    <input type="hidden" name="cert_method" value="01" />
    <input type="hidden" name="cert_otp_use" value="Y" />
    <input type="hidden" name="cert_enc_use_ext" value="Y" />
    <input type="hidden" name="web_siteid" value="" />
    <input type="hidden" name="Ret_URL" :value="returnURL" />
    <input type="hidden" name="param_opt_1" :value="param_opt_1" />
    <input type="hidden" name="param_opt_2" :value="param_opt_2" />
    <input type="hidden" name="param_opt_3" :value="param_opt_1" />
    <input type="hidden" name="veri_up_hash" />
    <input type="hidden" name="up_hash" :value="uphash" />
    <input type="hidden" name="kcp_cert_lib_ver" :value="version" />

    <iframe id="kcp_cert" name="kcp_cert" width="100%" height="700" frameborder="0" scrolling="no" style="display: none"></iframe>
</form>
</template>

<script>
export default {
    data() {
        return {
            siteCode: "",
            returnURL: "",
            orderID: "",
            uphash: "",
            gatewayURL: "",
            param_opt_1: "",
            param_opt_2: "",
            param_opt_3: "",
            version: "",
        }
    },
    mounted() {
        window.authComplete = ({ _certification, name, phone }) => {
            window?.parent?.authComplete({ _certification, name, phone });
            window?.opener?.authComplete({ _certification, name, phone });
        };

        this.auth();
    },
    beforeDestroy() {
        window.authComplete = undefined;
        window.auth = undefined;
    },
    methods: {
        async auth() {
            var { siteCode, returnURL, gatewayURL, certification } = await this.$http.post("/api/common/kcp/cert/request").then(res => res.data);

            this.siteCode = siteCode;
            this.returnURL = returnURL;
            this.gatewayURL = gatewayURL;
            this.orderID = certification._id;
            this.uphash = certification.upHash;
            this.version = certification.version;

            this.$nextTick(() => {
                var form = this.$refs.form;

                if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 )
                {
                    self.name = "auth_popup";
                }
                else{
                    var width = 410;
                    var height = 500;
                    var leftpos = screen.width / 2 - (width / 2);
                    var toppos = screen.height / 2 - (height / 2);
                    var feature = `width=${width}, height=${height}, toolbar=no, status=no, statusbar=no, menubar=no, scrollbars=no, resizable=no, left=${leftpos}, top=${toppos}`;
                    window.open('about:blank', 'auth_popup', feature);
                    form.target = "auth_popup";
                }
                form.submit();
            });
        },
    },
};
</script>
